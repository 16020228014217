import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`26th October 2019`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🥳 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now bind multiple paths to an annotated service easily using `}<inlineCode parentName="li">{`@Path`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1822"
        }}>{`#1822`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1870"
        }}>{`#1870`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Get
@Post
@Path("/a")
@Path("/b")
public String myAwesomeServcie(...) {...}
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now make an annotated service run from `}<inlineCode parentName="li">{`blockingTaskExecutor`}</inlineCode>{` by attaching `}<inlineCode parentName="li">{`@Blocking`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2078"
        }}>{`#2078`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2187"
        }}>{`#2187`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Get("/myHeavyTask")
@Blocking
public HttpResponse reallyHeavyComputation(...) {...}
`}</code></pre>
      </li>
      <li parentName="ul">{`Armeria server now adds `}<a parentName="li" {...{
          "href": "https://datatracker.ietf.org/doc/html/rfc7231#section-7.4.2"
        }}><inlineCode parentName="a">{`Server`}</inlineCode></a>{` and
`}<a parentName="li" {...{
          "href": "https://datatracker.ietf.org/doc/html/rfc7231#section-7.1.1.2"
        }}><inlineCode parentName="a">{`Date`}</inlineCode></a>{` headers to responses by default.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2137"
        }}>{`#2137`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2188"
        }}>{`#2188`}</a>
        <ul parentName="li">
          <li parentName="ul">{`If you do not want that behavior, you should call:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`Server.builder()
      .service(...)
      .disableServerHeader()
      .disableDateHeader()
      .build();
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`You can now configure a Caffeine cache spec for `}<inlineCode parentName="li">{`HttpFileService`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/916"
        }}>{`#916`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2142"
        }}>{`#2142`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpFileServiceBuilder.forClassPath("/")
                      .entryCacheSpec("maximumSize=512")
                      .build()
`}</code></pre>
        {`Or, using JVM option:
`}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.fileServiceCache=maximumSize=1024,expireAfterAccess=600s`}</inlineCode></li>
      <li parentName="ul">{`You can now see the Armeria version in the metric. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2179"
        }}>{`#2179`}</a></li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`💪 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Armeria server startup time is reduced to 80 percent when TLS is not used. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1645"
        }}>{`#1645`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2184"
        }}>{`#2184`}</a></li>
      <li parentName="ul">{`The performance of getting HTTP timestamps is improved by caching the values every second. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2196"
        }}>{`#2196`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`HttpTimestampSupplier.currentTime()`}</inlineCode>{` to take advantage of it.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The performance of finding service is improved by not making an unused `}<inlineCode parentName="li">{`Map`}</inlineCode>{` in `}<inlineCode parentName="li">{`RouteResult`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2153"
        }}>{`#2153`}</a></li>
      <li parentName="ul">{`The performance of gRPC call is improved by using the singleton HTTP headers when a service does not create a new HTTP headers. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2152"
        }}>{`#2152`}</a></li>
      <li parentName="ul">{`The performance of making metric `}<inlineCode parentName="li">{`Tag`}</inlineCode>{`s is improved by adding `}<inlineCode parentName="li">{`Tag`}</inlineCode>{`s in ascending order. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2150"
        }}>{`#2150`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2163"
        }}>{`#2163`}</a></li>
      <li parentName="ul">{`We now have favicon in our `}<inlineCode parentName="li">{`DocService`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2186"
        }}>{`#2186`}</a></li>
    </ul>
    <h2 {...{
      "id": "-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-bug-fixes",
        "aria-label": " bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`👻 Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ServerHttpRequest.getRemoteAddress()`}</inlineCode>{` now returns proper address. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2208"
        }}>{`#2208`}</a></li>
      <li parentName="ul">{`You can now see descriptive error messages when `}<inlineCode parentName="li">{`sslContext`}</inlineCode>{` is not configured properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1844"
        }}>{`#1844`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2124"
        }}>{`#2124`}</a></li>
      <li parentName="ul">{`You can now build `}<inlineCode parentName="li">{`(Request|Response)Headers`}</inlineCode>{` multiple times using builders. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2190"
        }}>{`#2190`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2193"
        }}>{`#2193`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Previously, it raised `}<inlineCode parentName="li">{`ClassCastException`}</inlineCode>{` if `}<inlineCode parentName="li">{`build()`}</inlineCode>{` is called twice.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`NoRequestContextException`}</inlineCode>{` is not raised anymore if you configure the name of the non-request thread.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`RequestContextCurrentTraceContext.builder()
                                 .nonRequestThread("RMI TCP Connection")
                                 .build()
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` is not raised anymore in `}<inlineCode parentName="li">{`Http1ClientCodec`}</inlineCode>{` when the server sends multiple responses for one request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2210"
        }}>{`#2210`}</a></li>
      <li parentName="ul">{`You can now see the access logs when the method of a request is not allowed and there are no services that match the path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2159"
        }}>{`#2159`}</a></li>
    </ul>
    <h2 {...{
      "id": "-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-breaking-changes",
        "aria-label": " breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🚫 Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`All annotated services are run from `}<inlineCode parentName="li">{`EventLoop`}</inlineCode>{` by default. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2187"
        }}>{`#2187`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Previously, if the return type is neither `}<inlineCode parentName="li">{`HttpResponse`}</inlineCode>{` nor `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{`, annotated services are run from `}<inlineCode parentName="li">{`blockingTaskExecutor`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ServerBuilder.tls()`}</inlineCode>{` now throws a checked `}<inlineCode parentName="li">{`SSLException`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2124"
        }}>{`#2124`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ServerBuilder.sslContext()`}</inlineCode>{` methods are completely removed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2124"
        }}>{`#2124`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.7.0 -> 5.8.0`}</li>
      <li parentName="ul">{`Dropwizard 4.1.0 -> 4.1.1`}</li>
      <li parentName="ul">{`gRPC 1.24.0 -> 1.24.1`}</li>
      <li parentName="ul">{`Netty 4.1.42 -> 4.1.43`}</li>
      <li parentName="ul">{`org.bouncycastle 1.63 -> 1.64`}</li>
      <li parentName="ul">{`Prometheus 0.7.0 -> 0.8.0`}</li>
      <li parentName="ul">{`RxJava2 2.2.12 -> 2.2.13`}</li>
      <li parentName="ul">{`Spring Boot 2.1.8 -> 2.1.9`}</li>
      <li parentName="ul">{`Tomcat`}
        <ul parentName="li">
          <li parentName="ul">{`9.0.26 -> 9.0.27`}</li>
          <li parentName="ul">{`8.5.43 -> 8.5.47`}</li>
        </ul>
      </li>
      <li parentName="ul">{`ZooKeeper 3.5.5 -> 3.5.6`}</li>
    </ul>
    <h2 {...{
      "id": "️-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-thank-you",
        "aria-label": "️ thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇‍♂️ Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'eunchan-kim', 'heowc', 'hirakida', 'ikhoon', 'imasahiro', 'jyblue', 'kojilin', 'matsumana', 'minwoox', 'mumgmangmange', 'rcsalome', 'taejs', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      